import ClipLoader from 'react-spinners/ClipLoader';

interface LoaderProps {
  size?: number;
  color?: string;
}

const Loader = ({ size = 100, color = '#1893D2' }: LoaderProps) => {
  return (
    <div style={{ minHeight: size, maxHeight: size, minWidth: size, maxWidth: size }}>
      <ClipLoader color={color} loading size={size} />
    </div>
  );
};

export default Loader;
