import moment from 'moment';

import { Layout, Loader, TransactionsFilters } from 'src/components';
import filterIcon from 'src/assets/filter.png';
import { TRANSACTION_STATUS } from 'src/types';

import { useTransactionsList } from './hooks';
import './TransactionsList.css';

interface TransactionsListProps {
  vendor?: boolean;
}

export const TransactionsList = ({ vendor }: TransactionsListProps) => {
  const {
    transactions,
    isLoading,
    reviewedTransactionsRef,
    isFiltersActive,
    status,
    onReviewClick,
    onStatusClick,
    onFiltersClear,
    toggleIsFiltersActive,
  } = useTransactionsList({ vendor });

  return (
    <Layout
      title="Transactions"
      hideBackArrow
      right={<img src={filterIcon} alt="Transactions filters" onClick={toggleIsFiltersActive} />}
      vendor={vendor}
    >
      <TransactionsFilters
        isActive={isFiltersActive}
        selectedStatus={status}
        onStatusClick={onStatusClick}
        onClear={onFiltersClear}
        onClose={toggleIsFiltersActive}
      />

      {transactions.map((transaction) => (
        <div key={transaction.id} className="transaction-card">
          <div className="transaction-info">
            <div className="transaction-title">{transaction.name}</div>
            <div className="transaction-subtitle">ID: {transaction.id}</div>
            <div className="transaction-subtitle">Status: {transaction.status}</div>
          </div>

          <div className="transaction-details">
            <div className="transaction-date">{moment(transaction.created_at).format('MMM, D')}</div>
            {!vendor && transaction.status === TRANSACTION_STATUS.Pending && (
              <button className="review-button" onClick={() => onReviewClick(transaction.id)}>
                Review
              </button>
            )}
          </div>
        </div>
      ))}

      {isLoading && <Loader size={40} />}

      {!isLoading && !transactions.length && (
        <span className="transactions-list__empty">
          {status ? `There are no transactions with status "${status}"` : 'There are no transactions yet'}
        </span>
      )}
      {!isLoading && <div ref={reviewedTransactionsRef} />}
    </Layout>
  );
};
