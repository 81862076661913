import './Modal.css';

interface ModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
  className?: string;
  children: React.ReactNode;
}

const Modal = ({ active, setActive, className, children }: ModalProps) => {
  return (
    <div
      className={active ? 'modal active' : 'modal'}
      onClick={() => {
        setActive(false);
      }}
    >
      <div
        className={`modal-content ${active ? 'active' : ''} ${className}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
