import _axios from 'axios';

import { store } from 'src/redux';
import { resetVendorState } from 'src/redux/action-creators';
import { BASE_URL } from 'src/constants';

export const axios = _axios.create({
  baseURL: BASE_URL,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const vendorToken = localStorage.getItem('vendorToken');
      if (vendorToken) {
        // Logout vendor on unauthenticated error
        localStorage.removeItem('vendorToken');
        localStorage.removeItem('currentVendor');
        localStorage.removeItem('currentVendorDevice');

        store.dispatch(resetVendorState());
      }
    }

    return Promise.reject(error);
  },
);
