export const makeUrlWithParams = (
  url: string,
  fields: { [key: string]: string | number | boolean | number[] | string[] } = {},
) => {
  let isFirst = true;

  return Object.keys(fields).reduce((acc, value) => {
    const val = fields[value];
    if (typeof val === 'undefined' || val === '' || val === null) return acc;
    const newUrl = `${isFirst ? '?' : '&'}${value}=${Array.isArray(val) ? JSON.stringify(val) : val}`;
    isFirst = false;

    return acc.concat(newUrl);
  }, url);
};
