import { PropsWithChildren } from 'react';

import { HeaderMobile, LayoutHeader, LayoutHeaderProps } from 'src/components';

import './Layout.css';

interface LayoutProps extends PropsWithChildren<LayoutHeaderProps> {
  vendor?: boolean;
  hideMobileHeader?: boolean;
  className?: string;
}

export const Layout = ({ children, vendor, hideMobileHeader, className = '', ...props }: LayoutProps) => {
  return (
    <div className="layout">
      {!hideMobileHeader && <HeaderMobile vendor={vendor} />}
      <LayoutHeader {...props} />

      <div className={`layout__inner ${className}`}>{children}</div>
    </div>
  );
};
