import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { transactionActions } from 'src/action';
import { useObserver } from 'src/hooks/useObserver';
import { Transaction, TRANSACTION_STATUS } from 'src/types';

interface UseTransactionsListParams {
  vendor?: boolean;
}

export const useTransactionsList = ({ vendor }: UseTransactionsListParams) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusParam = queryParams.get('status') as TRANSACTION_STATUS | null;

  const vendorToken = localStorage.getItem('vendorToken');
  const customerToken = useSelector(({ customerReducer: { customerToken } }: any) => customerToken);

  const token = vendor ? vendorToken : customerToken;

  const reviewedTransactionsRef = useRef();

  const [transactionsPage, setTransactionsPage] = useState(1);
  const [transactionsTotalPages, setTransactionsTotalPages] = useState(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const [status, setStatus] = useState<TRANSACTION_STATUS | null>(statusParam);

  useObserver(reviewedTransactionsRef, transactionsPage < transactionsTotalPages, isLoading, () => {
    setTransactionsPage(transactionsPage + 1);
  });

  const getTransactions = useCallback(async () => {
    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await transactionActions.getTransactions({
        page: transactionsPage,
        token,
        userType: vendor ? 'vendor' : 'customer',
        status,
      });

      if (transactionsPage > data.last_page) {
        setIsLoading(false);
        return;
      }

      setTransactionsTotalPages(data.last_page);
      setTransactions((prevTransactions) => [...prevTransactions, ...data.data]);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [status, token, transactionsPage, vendor]);

  const onReviewClick = (transactionId: number) => {
    history.push(`/customer/transactions/${transactionId}/leave-review`);
  };

  const onStatusClick = (status: TRANSACTION_STATUS) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('status', status);
    history.replace({ pathname: location.pathname, search: searchParams.toString() });

    setIsFiltersActive(false);
  };

  const onFiltersClear = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('status');
    history.replace({ pathname: location.pathname, search: searchParams.toString() });

    setIsFiltersActive(false);
  };

  const toggleIsFiltersActive = () => {
    setIsFiltersActive((prev) => !prev);
  };

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    if (status !== statusParam) {
      setStatus(statusParam);
      setTransactions([]);
      setTransactionsPage(1);
    }
  }, [status, statusParam]);

  return {
    transactions,
    isLoading,
    reviewedTransactionsRef,
    isFiltersActive,
    status,
    onReviewClick,
    onStatusClick,
    onFiltersClear,
    toggleIsFiltersActive,
  };
};
