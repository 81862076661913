import { axios } from 'src/utils';

const getInviteCode = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get('/invite-code', config);
  return response.data;
};

const sendInvite = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post('/invite', data, config);
  return response.data;
};

export { getInviteCode, sendInvite };
