import { Modal } from 'src/components';

import './MessageModal.css';

interface MessageModalProps {
  active: boolean;
  message: string;
  onClose: () => void;
}

export const MessageModal = ({ active, message, onClose }: MessageModalProps) => {
  return (
    <Modal active={active} setActive={onClose} className="message-modal">
      <div className="message-modal__inner">
        <span>{message}</span>
        <button onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
};
