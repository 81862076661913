import { useEffect, useState } from 'react';

import { Loader, MessageModal } from 'src/components';
import { passwordActions, verificationActions } from 'src/action';

import './ResendPin.css';

const RESEND_PIN_TIMEOUT = 60;

interface ResendPinProps {
  type: 'email' | 'phone';
  userId?: string | number;
  resetPassword?: boolean;
  email?: string;
  onError?: (error: string) => void;
  className?: string;
}

export const ResendPin = ({ userId, type, resetPassword, email, onError, className }: ResendPinProps) => {
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const [isResendPinLoading, setIsResendPinLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleResendPinClick = async () => {
    try {
      setIsResendPinLoading(true);

      if (resetPassword) {
        await passwordActions.sendResetPasswordPin({ email });
      } else {
        await verificationActions.resendVerifyPin({ user_id: userId, user_type: 'customer', type_flag: type });
      }

      setMessage(
        type === 'email'
          ? 'A new PIN has been sent to your email address.'
          : 'A new PIN has been sent to your phone number.',
      );

      setCanResend(false);
      setTimer(RESEND_PIN_TIMEOUT);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong';
      onError?.(errorMessage);
    } finally {
      setTimeout(() => {
        setIsResendPinLoading(false);
      }, 1000);
    }
  };

  const handleCloseMessageModal = () => {
    setMessage('');
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!canResend && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            setCanResend(true);

            return 0;
          }

          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [canResend, timer]);

  return (
    <>
      <div className={`resend-pin ${className}`}>
        {canResend ? (
          <>
            {type === 'email' ? (
              <>
                <p>If you don't receive the code, check</p>
                <p>your spam folder or</p>
              </>
            ) : (
              <p>If you don't receive the code</p>
            )}
            {isResendPinLoading ? <Loader size={22} /> : <p onClick={handleResendPinClick}>ask to send a new code.</p>}
          </>
        ) : (
          <>
            <p className="resend-pin__timer">
              Please wait <mark>{timer}</mark> seconds to
            </p>
            <p className="resend-pin__timer">ask to send a new code.</p>
          </>
        )}
      </div>

      <MessageModal active={Boolean(message)} message={message} onClose={handleCloseMessageModal} />
    </>
  );
};
