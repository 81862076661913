import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import useRoutes from './routes';
import useAuth from "./hooks/auth.hook";

import ScrollTop from "./components/ScrollTop/ScrollTop";

function App() {
    const isAuthenticated = useAuth();
    const routes = useRoutes(isAuthenticated);

    return (
        <Router>
            <ScrollTop />
            {routes}
        </Router>
    );
}

export default App;
