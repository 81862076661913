import { axios } from 'src/utils';
import { ApiResponse, Review } from 'src/types';

const leaveReview = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post('/user/review', data, config);

  return response.data;
};

const getReviews = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get<ApiResponse<Review[]>>('/reviews', config);

  return response.data;
};

const getReview = async (token: string, id: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get<ApiResponse<Review[]>>(`/reviews/${id}`, config);

  return response.data;
};

export { leaveReview, getReviews, getReview };
