import React from 'react';
import { useSelector } from 'react-redux';

const useSubdomain = () => {
  const customerToken = useSelector<any>(({ customerReducer: { customerToken } }) => customerToken);
  const vendorToken = useSelector<any>(({ vendorReducer: { vendorToken } }) => vendorToken);

  if (!customerToken && !vendorToken) {
    return window.location.href.indexOf('/customer') > 0 ? '/customer' : '/vendor';
  }

  return customerToken ? '/customer' : vendorToken ? '/vendor' : '/';
};

export default useSubdomain;
