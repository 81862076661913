import { TRANSACTION_STATUS } from 'src/types';

import './TransactionsFilters.css';

const AVAILABLE_STATUSES = [TRANSACTION_STATUS.Pending, TRANSACTION_STATUS.Reviewed];

interface TransactionsFiltersProps {
  isActive: boolean;
  selectedStatus: TRANSACTION_STATUS | null;
  onStatusClick: (status: TRANSACTION_STATUS) => void;
  onClear: () => void;
  onClose: () => void;
}

export const TransactionsFilters = ({
  isActive,
  selectedStatus,
  onStatusClick,
  onClear,
  onClose,
}: TransactionsFiltersProps) => {
  return (
    <div className={`transactions-filters ${isActive ? 'active' : ''}`} onClick={onClose}>
      <div className="transactions-filters__inner" onClick={(e) => e.stopPropagation()}>
        <div className="transactions-filters__title">Filter by:</div>

        {AVAILABLE_STATUSES.map((status) => (
          <div
            key={status}
            className={`transactions-filters__option ${selectedStatus === status ? 'selected' : ''}`}
            onClick={() => onStatusClick(status)}
          >
            {status}
          </div>
        ))}

        {selectedStatus && (
          <div className="transactions-filters__option clear" onClick={onClear}>
            Clear
          </div>
        )}
        <div className="transactions-filters__option" onClick={onClose}>
          Cancel
        </div>
      </div>
    </div>
  );
};
