import {SET_PROVIDER_REVIEWS} from "../action-types";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
	reviews: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PROVIDER_REVIEWS: {
			return {
				...state,
				reviews: action.payload
			}
		}

		default: return state;
	}
}
const persistConfig = {
    key: 'reviews',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
