import { SET_QR_RESULT } from '../action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  qr: '',
  uuid: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QR_RESULT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'qr',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
