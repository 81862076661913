import { useState, useEffect } from 'react';

import './RouteFallback.css';

export const RouteFallback = () => {
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowReload(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div className="fallback">
      Loading...
      {showReload && (
        <>
          <span className="fallback__text">It's taking longer than expected. Please try reloading the page.</span>
          <button onClick={handleReloadClick} className="fallback__button">
            Reload
          </button>
        </>
      )}
    </div>
  );
};
