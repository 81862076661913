import { SET_ERROR, CLEAR_ERROR } from '../action-types/error-action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  errorMessage: null,
  reason: '',
  instructions: null,
  whoLeftFlow: '',
  isConfirmationModalActive: false,
  navigateTo: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      const instructions = action.payload.instructions ?? null;
      return {
        ...state,
        ...action.payload,
        instructions,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
        reason: '',
        instructions: null,
        whoLeftFlow: '',
      };
    case 'SET_WHO_LEFT_FLOW':
      return {
        ...state,
        whoLeftFlow: action.payload,
      };
    case 'SET_IS_CONFIRMATION_MODAL_ACTIVE':
      return {
        ...state,
        isConfirmationModalActive: action.payload,
      };

    case 'SET_NAVIGATE_TO':
      return {
        ...state,
        navigateTo: action.payload,
      };
    default:
      return state;
  }
};
const persistConfig = {
  key: 'error',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
