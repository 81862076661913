export enum TRANSACTION_STATUS {
  Pending = 'pending',
  Reviewed = 'reviewed',
  Rejected = 'rejected',
}

export interface Transaction {
  id: number;
  name: string;
  vendor_id: number;
  customer_id: number;
  device_id: string;
  transaction_amount: number;
  morewards_spent: number;
  credit_card_amount: number;
  morewards_earned: number;
  created_at: string;
  updated_at: string;
  status: TRANSACTION_STATUS;
}
