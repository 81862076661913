const SET_CUSTOMER_TOKEN = 'SET_CUSTOMER_TOKEN';
const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
const SET_FRIEND_INVITES_LEFT = 'SET_FRIEND_INVITES_LEFT';
const SET_BLE_FLOW_STARTED = 'SET_BLE_FLOW_STARTED';
const SET_PERMISSIONS_GRANTED = 'SET_PERMISSIONS_GRANTED';
export {
  SET_CURRENT_CUSTOMER,
  SET_PERMISSIONS_GRANTED,
  SET_CUSTOMER_TOKEN,
  SET_FRIEND_INVITES_LEFT,
  SET_BLE_FLOW_STARTED,
};
