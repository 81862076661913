export * from './input';

export const BASE_URL = process.env.REACT_APP_BASE_URI ?? '/api';

export enum DISCONNECTION_REASONS {
  Permissions = 'permissions',
  Disconnect = 'disconnect',
}

export enum BleReasonDisconnected {
  SUCCESS_FLOW = 'SUCCESS_FLOW',
  EXIT_FLOW = 'EXIT_FLOW',
  NO_PERMISSIONS = 'NO_PERMISSIONS',
  BLUETOOTH_TURN_OFF = 'BLUETOOTH_TURN_OFF',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
}

export enum WEBVIEW_EVENTS {
  // POS registration
  StartScanPos = 'start_scan_pos',
  QrScannedPos = 'qr_scanned_pos',

  // QR scanning
  StartScanQr = 'start_scan_qr',
  QrScanned = 'qr_scanned',
  QrCanceled = 'qr_canceled',

  // Bluetooth
  RequestPermissions = 'request_permissions',
  PermissionsDenied = 'permissions_denied',
  PermissionsGranted = 'permissions_granted',

  CustomerConnected = 'customer_connected',
  VendorConnected = 'vendor_connected',

  WriteAmount = 'write_amount',
  WriteRefund = 'write_refund',

  RefundSent = 'refund_sent',
  AmountSent = 'amount_sent',

  AmountReceived = 'amount_received',
  RefundReceived = 'refund_received',

  Disconnect = 'disconnect',
  DevicesDisconnected = 'devices_disconnected',

  // WebView Lifecycle
  AppCheck = 'app_check',
  AppAlive = 'app_alive',
}
