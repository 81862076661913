import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  SET_VENDOR_TOKEN,
  SET_CURRENT_VENDOR,
  SET_VENDOR_TRANSACTION,
  SET_CURRENT_VENDOR_DEVICE,
  RESET_VENDOR_STATE,
} from '../action-types';

const initialState = {
  vendorToken: null,
  currentVendor: null,
  currentVendorDevice: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENDOR_TOKEN: {
      return {
        ...state,
        vendorToken: action.payload,
      };
    }

    case SET_CURRENT_VENDOR: {
      return {
        ...state,
        currentVendor: action.payload,
      };
    }

    case SET_CURRENT_VENDOR_DEVICE: {
      return {
        ...state,
        currentVendorDevice: action.payload,
      };
    }

    case SET_VENDOR_TRANSACTION:
      return { ...state, transaction: { ...state.transaction, ...action.payload } };

    case RESET_VENDOR_STATE:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  key: 'vendor',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
