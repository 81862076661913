import {
  SET_VENDOR_TOKEN,
  SET_VENDOR_TRANSACTION,
  SET_CURRENT_VENDOR,
  SET_CURRENT_VENDOR_DEVICE,
  RESET_VENDOR_STATE,
} from '../action-types';

export const setVendorToken = (response) => ({ type: SET_VENDOR_TOKEN, payload: response });
export const setCurrentVendor = (response) => ({ type: SET_CURRENT_VENDOR, payload: response });
export const setCurrentVendorDevice = (response) => ({ type: SET_CURRENT_VENDOR_DEVICE, payload: response });
export const setVendorTransaction = (amount, refund) => ({
  type: SET_VENDOR_TRANSACTION,
  payload: { amount, refund },
});
export const resetVendorState = () => ({ type: RESET_VENDOR_STATE });
