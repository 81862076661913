import { axios } from 'src/utils';
import { ApiResponse, Balance, Customer } from 'src/types';

const customerLogin = async (data) => {
  return await axios.post(`/auth/login/customer`, data);
};

const customerSignup = async (data) => {
  return await axios.post(`/auth/register/customer`, data);
};

const updateCustomer = async (data, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.put<ApiResponse<unknown>>('/user', data, config);
};

interface GetCustomerResponse {
  user: Customer;
  balance: Balance;
}

const getCustomer = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get<ApiResponse<GetCustomerResponse>>('/user', config);
};

const getAllCustomers = async (token, page) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`/customers/page/${page}`, config);
  return response.data;
};

const residualCustomer = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post('/residual-customer', data, config);
  return response.data;
};

const createTransaction = async (data, token) => {
  // const config = {
  //     headers: {
  //         Authorization: `Bearer ${token}`
  //     }
  // }

  const response = await axios.post('/transaction', data);
  return response.data;
};

const getCustomerBalance = async (customer_id, token) => {
  // const config = {
  //     headers: {
  //         Authorization: `Bearer ${token}`
  //     }
  // };

  const response: any = await axios.get('/transaction/balance', {
    params: { customer_id },
    // ...config
  });

  return response.data.data;
};

const getVendorDetails = async (vendor_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response: any = await axios.get(`/vendors/${vendor_id}`, {
    ...config,
  });

  return response.data.data;
};

const checkPhone = async (phone, user_type) => {
  return await axios.post('/auth/check-phone', { phone, user_type });
};

export {
  customerLogin,
  customerSignup,
  updateCustomer,
  getCustomer,
  getAllCustomers,
  residualCustomer,
  createTransaction,
  getCustomerBalance,
  checkPhone,
  getVendorDetails,
};
