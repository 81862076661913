import {
  SET_CUSTOMER_TOKEN,
  SET_CURRENT_CUSTOMER,
  SET_FRIEND_INVITES_LEFT,
  SET_BLE_FLOW_STARTED,
  SET_PERMISSIONS_GRANTED,
} from '../action-types';

const setCustomerToken = (response) => ({ type: SET_CUSTOMER_TOKEN, payload: response });
const setCustomerBalance = (response) => ({ type: 'SET_CUSTOMER_BALANCE', payload: response });
const setCurrentCustomer = (response) => ({ type: SET_CURRENT_CUSTOMER, payload: response });
const setFriendInvitesLeft = (response) => ({ type: SET_FRIEND_INVITES_LEFT, payload: response });
const setIsBLEStarted = (response) => ({ type: SET_BLE_FLOW_STARTED, payload: response });
const setIsPermissionsGranted = (response) => ({ type: SET_PERMISSIONS_GRANTED, payload: response });

const setPendingTransaction = (transaction) => ({
  type: 'SET_PENDING_TRANSACTION',
  payload: transaction,
});

const clearPendingTransaction = () => ({
  type: 'CLEAR_PENDING_TRANSACTION',
});

const setTransaction = (transaction) => ({
  type: 'SET_TRANSACTION',
  payload: transaction,
});

const setTransactions = (transactions) => ({
  type: 'SET_TRANSACTIONS',
  payload: transactions,
});

export {
  setCustomerToken,
  setCurrentCustomer,
  setFriendInvitesLeft,
  setPendingTransaction,
  clearPendingTransaction,
  setTransaction,
  setTransactions,
  setIsBLEStarted,
  setIsPermissionsGranted,
  setCustomerBalance,
};
