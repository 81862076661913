import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import './PinInput.css';

interface PinInputProps {
  length?: number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
}

export interface PinInputRef {
  reset: () => void;
}

export const PinInput = forwardRef<PinInputRef, PinInputProps>(
  ({ length = 6, onChange, disabled = false, className = '' }, ref) => {
    const [code, setCode] = useState<string[]>(Array(length).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleChange = (index: number, value: string) => {
      if (!/^\d*$/.test(value)) return;

      const newCode = [...code];
      const digits = value.split('');

      for (let i = 0; i < digits.length && index + i < length; i++) {
        newCode[index + i] = digits[i];
      }

      setCode(newCode);
      onChange?.(newCode.join(''));

      const nextIndex = index + digits.length < length ? index + digits.length : length - 1;
      inputRefs.current[nextIndex]?.focus();
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace') {
        e.preventDefault();
        const newCode = [...code];

        if (code[index]) {
          newCode[index] = '';
        } else if (index > 0) {
          newCode[index - 1] = '';
          inputRefs.current[index - 1]?.focus();
        }

        setCode(newCode);
        onChange?.(newCode.join(''));
      }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('text');

      if (/^\d+$/.test(pasteData) && pasteData.length <= length) {
        const newCode = [...code];
        for (let i = 0; i < pasteData.length; i++) {
          newCode[i] = pasteData[i];
        }
        setCode(newCode);

        const nextIndex = pasteData.length < length ? pasteData.length : length - 1;
        inputRefs.current[nextIndex]?.focus();
      }
    };

    const reset = () => {
      setCode(Array(length).fill(''));
      onChange?.('');
    };

    useImperativeHandle(ref, () => ({
      reset,
    }));

    return (
      <div className={`pin-input ${className}`}>
        {Array.from({ length }, (_, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            value={code[index]}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            ref={(el) => (inputRefs.current[index] = el)}
            disabled={disabled}
            aria-label={`Digit ${index + 1}`}
            autoComplete={index === 0 ? 'one-time-code' : 'off'}
            pattern="\d"
          />
        ))}
      </div>
    );
  },
);
