import { axios } from 'src/utils';

const addProviderToFavorite = async (token, vendorId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`/user/favorites/${vendorId}`, config);

  return response.data;
};

const getFavoriteProviders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get('/user/favorites', config);
  return response.data;
};

const getAllProviders = async (token, page) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`/vendors/page/${page}`, config);
  return response.data;
};

export { addProviderToFavorite, getFavoriteProviders, getAllProviders };
