import './Alert.css';

interface AlertProps {
  text: string;
  type?: 'success' | 'error';
  className?: string;
}

export const Alert = ({ text, type = 'success', className }: AlertProps) => {
  return <div className={`alert ${type} ${className}`}>{text}</div>;
};
