import { PropsWithChildren } from 'react';

import { Modal } from 'src/components';
import crossIcon from 'src/assets/close.png';

import './ErrorModal.css';

interface ErrorModalProps extends PropsWithChildren {
  error?: string;
  onClose: () => void;
}

export const ErrorModal = ({ error, onClose }: ErrorModalProps) => {
  return (
    <Modal active={Boolean(error)} setActive={onClose}>
      <div className="error-modal__inner">
        <img src={crossIcon} alt="cross" onClick={onClose} />

        <div>
          <span>Error</span>

          <div>{error}</div>
        </div>
      </div>
    </Modal>
  );
};
