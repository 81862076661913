import { axios } from 'src/utils';

const getCategoriesListParent = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get('/categories/parent', config);
  return response.data;
};

const getCategoriesListSub = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`/categories/sub/${id}`, config);

  return response.data;
};

const getCategoryVendor = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`/categories/${id}/vendors`, config);
  return response.data;
};

export { getCategoriesListParent, getCategoriesListSub, getCategoryVendor };
