import { axios } from 'src/utils';

// 320|S0NpMCMndIHqGSF5fDC9bLjRrBjpvr0jY9B9ybC0

const getJobs = async (page, token) => {
    console.log(page + 'token: ', token)

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    // const response = fetch('http://pwa-backend.locallyselected.com/api/user/jobs', {
    //     method: 'GET',
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }).then(function (response) {
    //     response.json().then(function (data) {
    //         console.log('data', data)
    //     })
    // })
    //
    // console.log('response : ', response.data)

    const response = await axios.get(`/user/jobs/page/${page}`, config);

    console.log(response.data);

    return response.data;

    // return response;
    // return {
    //     ok: true,
    //     data: {
    //         jobs: [
    //             {
    //                 id: 3863,
    //                 parent_id: 3862,
    //                 user_id: 214,
    //                 user_id_alt: 0,
    //                 user_id_provider: null,
    //                 status: 'residual',
    //                 amount_paid: 0.3,
    //                 title: 'Bonus Rewards',
    //                 area: "residual_27782",
    //                 created_at: "2021-12-22T12:26:00.000000Z",
    //                 updated_at: "2021-12-22T13:21:05.000000Z",
    //                 friends_only: "N",
    //                 deleted_at: null,
    //                 is_delete: 0,
    //                 review: null,
    //                 user_provider: null
    //             },
    //             {
    //                 id: 3861,
    //                 parent_id: 3824,
    //                 user_id: 214,
    //                 user_id_alt: 0,
    //                 user_id_provider: null,
    //                 status: "refund",
    //                 amount_paid: -25.2,
    //                 title: "Refund",
    //                 area: "refund_27773",
    //                 created_at: "2021-12-21T10:41:00.000000Z",
    //                 updated_at: "2021-12-21T11:35:55.000000Z",
    //                 friends_only: "N",
    //                 deleted_at: null,
    //                 is_delete: 0,
    //                 review: null,
    //                 user_provider: null
    //             },
    //             {
    //                 id: 3856,
    //                 parent_id: 3807,
    //                 user_id: 214,
    //                 user_id_alt: 0,
    //                 user_id_provider: null,
    //                 status: "reward",
    //                 amount_paid: 25.2,
    //                 title: "Rewards for Review",
    //                 area: "reward_27755",
    //                 created_at: "2021-12-20T11:46:00.000000Z",
    //                 updated_at: "2021-12-20T12:41:24.000000Z",
    //                 friends_only: "N",
    //                 deleted_at: null,
    //                 is_delete: 0,
    //                 review: null,
    //                 user_provider: null
    //             },
    //             {
    //                 id: 3862,
    //                 parent_id: null,
    //                 user_id: 214,
    //                 user_id_alt: 0,
    //                 user_id_provider: 156,
    //                 status: "pending",
    //                 amount_paid: 10,
    //                 title: "Bike Rentals",
    //                 area: "554478",
    //                 created_at: "2021-12-22T13:21:04.000000Z",
    //                 updated_at: "2021-12-22T13:21:04.000000Z",
    //                 friends_only: "N",
    //                 deleted_at: null,
    //                 is_delete: 0,
    //                 review: null,
    //                 user_provider: {
    //                     id: 156,
    //                     tin: "123456",
    //                     name: "New Interexy",
    //                     url: "www.dummy.com",
    //                     main_tel: "+11791038482",
    //                     contact_lname: null,
    //                     contact_fname: null,
    //                     contact_email: "vendingtestuser@gmail.com",
    //                     contact_mob_tel: "+11791038482",
    //                     contact_work_tel: "+11791038482",
    //                     mrbe_id: 2124,
    //                     invite_customer_mrbe_id: null,
    //                     mrbe_status: 1,
    //                     created_at: "2021-05-07T16:25:53.000000Z",
    //                     updated_at: "2021-05-07T16:25:53.000000Z",
    //                     auth_code: "BnaWRredJKQ0TGV"
    //                 }
    //             }
    //         ]
    //     }
    // }
}

const getJobById = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return await axios.get(`/user/jobs/${id}`, config);
}

const qrCodePurchaseReturn = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return await axios.post(`/get-purchase`, data, config);
}

export {
    getJobs,
    getJobById,
    qrCodePurchaseReturn
}
