import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useSubdomain from 'src/hooks/useSubdomain';
import accountIcon from 'src/assets/menu/account.svg';
import homeIcon from 'src/assets/menu/home.svg';
import activitiesIcon from 'src/assets/menu/activities.svg';
import directoryIcon from 'src/assets/menu/directory.svg';
import { clearPendingTransaction, setIsBLEStarted, setVendorTransaction } from 'src/redux/action-creators';

import './HeaderMobile.css';
import { useDispatch, useSelector } from 'react-redux';

interface HeaderMobileProps {
  vendor?: boolean;
}

const HeaderMobile = ({ vendor }: HeaderMobileProps) => {
  const subdomain = useSubdomain();
  const [menuActive, setMenuActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const customerTokenLocal = useSelector<any, any>(({ customerReducer: { customerToken } }) => customerToken);
  const isBleStarted = useSelector<any, any>(({ customerReducer: { isBleStarted } }) => isBleStarted);

  const clearBleTransactionData = useCallback(() => {
    if (!!customerTokenLocal) {
      dispatch(clearPendingTransaction());
    } else {
      dispatch(setVendorTransaction(null, null));
    }
    dispatch(setIsBLEStarted(false));
  }, [customerTokenLocal]);

  useEffect(() => {
    const handleMessageFromRN = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.event === 'devices_disconnected') {
        clearBleTransactionData();
        history.push(`${subdomain}`);
        return;
      }
    };

    document.addEventListener('message', handleMessageFromRN);

    return () => {
      document.removeEventListener('message', handleMessageFromRN);
    };
  }, []);

  const finishBleFlow = () => {
    if (isBleStarted) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'disconnect' }));
      }
    } else {
      clearBleTransactionData();
      history.push(`${subdomain}`);
    }
  };

  const handleLink = (link: string) => {
    return `${subdomain}/${link}`;
  };

  return (
    <>
      <div className={menuActive ? 'menu-wrapper active' : 'menu-wrapper'}>
        <div className="menu-btn__wrapper">
          <div>
            <div className={menuActive ? 'menu active' : 'menu'}>Menu</div>
          </div>
          <div
            className={menuActive ? 'menu-btn active' : 'menu-btn'}
            onClick={() => {
              setMenuActive(!menuActive);
            }}
          >
            <div className="menu-btn__burger" />
          </div>
        </div>

        <div className={menuActive ? 'menu-navigation active' : 'menu-navigation'}>
          <nav className="menu-m-nav">
            <div
              className="nav-link"
              onClick={() => {
                history.push(handleLink('account'));
              }}
            >
              <img src={accountIcon} alt="account page" />
              <div>Account</div>
            </div>
            <div
              className="nav-link"
              onClick={() => {
                history.push(subdomain);
              }}
            >
              <img src={homeIcon} alt="home page" />
              <div>Home</div>
            </div>
            {/* <div
              className="nav-link activities"
              onClick={() => {
                history.push(handleLink('activities'));
              }}
            >
              <img src={activitiesIcon} alt="activities page" />
              <div className="new-activities__parent">
                Activities
                {!!newActivitiesCount && <span className="new-activities">{newActivitiesCount}</span>}
              </div>
              // <span className={'new-activities'}>9</span>
            </div> */}
            <div
              className="nav-link"
              onClick={() => {
                history.push(handleLink('transactions'));
              }}
            >
              <img src={activitiesIcon} alt="transactions page" />
              <div>Transactions</div>
            </div>
            {!vendor && (
              <div
                className="nav-link"
                onClick={() => {
                  history.push(handleLink('directory'));
                }}
              >
                <img src={directoryIcon} alt="directory page" />
                <div>Directory</div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default HeaderMobile;
