import {
    SET_MESSAGE_ACTIVE,
    SET_PASSWORD_CHANGED,
    SET_ACCOUNT_EDITED,
    SET_PURCHASE_RETURNED,
    SET_PROVIDER_INVITE_SENT,
} from '../action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
    messageActive: false,
    passwordChanged: false,
    accountEdited: false,
    purchaseReturned: false,
    providerInviteSent: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MESSAGE_ACTIVE: {
            return {
                ...state,
                messageActive: action.payload
            }
        }

        case SET_PASSWORD_CHANGED: {
            return {
                ...state,
                passwordChanged: action.payload
            }
        }

        case SET_ACCOUNT_EDITED: {
            return {
                ...state,
                accountEdited: action.payload
            }
        }

        case SET_PURCHASE_RETURNED: {
            return {
                ...state,
                purchaseReturned: action.payload
            }
        }

        case SET_PROVIDER_INVITE_SENT: {
            return {
                ...state,
                providerInviteSent: action.payload
            }
        }

        default: return state;
    }
}

const persistConfig = {
    key: 'message',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;