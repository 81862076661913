const SET_MESSAGE_ACTIVE = 'SET_MESSAGE_ACTIVE';
const SET_PASSWORD_CHANGED = 'SET_PASSWORD_CHANGED';
const SET_ACCOUNT_EDITED = 'SET_ACCOUNT_EDITED';
const SET_PURCHASE_RETURNED = 'SET_PURCHASE_RETURNED';
const SET_PROVIDER_INVITE_SENT = 'SET_INVITE_SENT';

export {
    SET_MESSAGE_ACTIVE,
    SET_PASSWORD_CHANGED,
    SET_ACCOUNT_EDITED,
    SET_PURCHASE_RETURNED,
    SET_PROVIDER_INVITE_SENT,
}
