import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { useForm } from 'react-hook-form';

import { Loader, Layout } from 'src/components';
import { providerActions, transactionActions, vendorActions } from 'src/action';
import heartIcon from 'src/assets/activity-icons/heart-icon.svg';
import heartFavoriteIcon from 'src/assets/providers-icons/heart-favorite.svg';
import smileIcon from 'src/assets/activity-icons/smile.svg';
import { MAX_REVIEW_LENGTH } from 'src/constants';

import './LeaveReview.css';

interface RouteParams {
  id: string;
}

export const LeaveReview = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const customerToken = useSelector(({ customerReducer: { customerToken } }: any) => customerToken);

  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [vendorId, setVendorId] = useState<number>();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      review: '',
    },
    mode: 'onBlur',
  });

  const review = watch('review', '');

  const getTransaction = useCallback(async () => {
    try {
      setIsTransactionLoading(true);
      const {
        data: { data },
      } = await transactionActions.getTransactionById({ id, token: customerToken, userType: 'customer' });

      const res = await vendorActions.getVendorById(data.vendor_id, customerToken);

      setIsFavorite(res.data.isFavorite);
      setVendorId(res.data.id);
    } catch (error) {
    } finally {
      setIsTransactionLoading(false);
    }
  }, [customerToken, id]);

  const sendReview = async ({ review }: { review: string }) => {
    try {
      const { data } = await transactionActions.leaveReview({
        id,
        token: customerToken,
        data: { review, rating },
      });

      if (data.ok) {
        setIsReviewSubmitted(true);
      }
    } catch (error) {}
  };

  const handleAddOrRemoveProviderFromFavorites = async () => {
    if (isFavoriteLoading) return;

    try {
      setIsFavoriteLoading(true);

      const response: any = await providerActions.addProviderToFavorite(customerToken, vendorId);
      if (response.ok) {
        setIsFavorite(!isFavorite);
      }
    } catch (error) {
    } finally {
      setIsFavoriteLoading(false);
    }
  };

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <Layout title="Leave a review" className="leave-review">
      {!isReviewSubmitted ? (
        <>
          <span>Please, enter a review below</span>

          <form noValidate onSubmit={handleSubmit(sendReview)}>
            <textarea
              id="review"
              {...register('review')}
              placeholder="Write your review here"
              rows={8}
              maxLength={MAX_REVIEW_LENGTH}
            />
            {review.length === MAX_REVIEW_LENGTH && (
              <span className="review-length-error">
                Your review exceeds the maximum length of {MAX_REVIEW_LENGTH} characters.
              </span>
            )}

            <StarRatings
              rating={rating}
              starRatedColor="rgba(255, 188, 58, 1)"
              starHoverColor="rgba(255, 188, 58, 1)"
              changeRating={(rating: number) => setRating(rating)}
              numberOfStars={5}
              starSpacing="4px"
              starDimension="42px"
              name="rating"
              svgIconViewBox="0 0 15 14"
              svgIconPath="M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z"
            />

            <button className="review-button active" disabled={!rating || isSubmitting}>
              {isSubmitting ? <Loader size={20} color="#fff" /> : 'Save'}
            </button>
          </form>

          {isTransactionLoading ? (
            <Loader size={32} />
          ) : (
            <div className="favorite-vendor" onClick={handleAddOrRemoveProviderFromFavorites}>
              {isFavoriteLoading ? (
                <Loader size={32} />
              ) : (
                <img src={isFavorite ? heartFavoriteIcon : heartIcon} alt="favorite vendor" />
              )}
              <span>{isFavorite ? 'Remove vendor from favorites' : 'Add vendor to favorites'}</span>
            </div>
          )}
        </>
      ) : (
        <>
          <img className="smile-img" src={smileIcon} alt="smile" />
          <span className="thx">Thank you!</span>
          <div className="thx-text">
            Your review has been received and the rewards associated with this job will be issued shortly.
          </div>
          <button className="review-button bold" onClick={history.goBack}>
            Back to transactions
          </button>
        </>
      )}
    </Layout>
  );
};
