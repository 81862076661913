import {
  SET_CUSTOMER_TOKEN,
  SET_BLE_FLOW_STARTED,
  SET_CURRENT_CUSTOMER,
  SET_FRIEND_INVITES_LEFT,
  SET_PERMISSIONS_GRANTED,
} from '../action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  customerToken: null,
  currentCustomer: null,
  currentBalance: {
    balance: 0,
    pendingBalance: 0,
  },
  friendInvitesLeft: 0,
  pendingTransaction: null,
  transactions: [],
  isBleStarted: false,
  isPermissionsGranted: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_TOKEN: {
      return {
        ...state,
        customerToken: action.payload,
      };
    }

    case 'SET_CUSTOMER_BALANCE': {
      return {
        ...state,
        currentBalance: action.payload,
      };
    }

    case SET_CURRENT_CUSTOMER: {
      if (!state.currentCustomer) {
        return {
          ...state,
          currentCustomer: action.payload,
        };
      }
      return {
        ...state,
        currentCustomer: { ...state.currentCustomer, ...action.payload },
      };
    }

    case SET_FRIEND_INVITES_LEFT: {
      return {
        ...state,
        friendInvitesLeft: action.payload,
      };
    }

    case 'SET_PENDING_TRANSACTION':
      return {
        ...state,
        pendingTransaction: { ...state.pendingTransaction, ...action.payload },
      };

    case 'CLEAR_PENDING_TRANSACTION':
      return {
        ...state,
        pendingTransaction: null,
      };

    case 'SET_TRANSACTION':
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      };

    case 'SET_TRANSACTIONS':
      return {
        ...state,
        transactions: action.payload,
      };

    case SET_BLE_FLOW_STARTED: {
      return {
        ...state,
        isBleStarted: action.payload,
      };
    }
    case SET_PERMISSIONS_GRANTED: {
      return {
        ...state,
        isPermissionsGranted: action.payload,
      };
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'customer',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
