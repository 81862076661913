import { axios, makeUrlWithParams } from 'src/utils';
import { ApiResponse, AxiosApiResponse, Balance, Transaction, TRANSACTION_STATUS } from 'src/types';

interface GetTransactionsRequest {
  page: number;
  token: string;
  userType: 'vendor' | 'customer';
  limit?: number;
  status?: TRANSACTION_STATUS;
}

interface GetTransactionsResponse {
  ok: boolean;
  data: Transaction[];
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

const getTransactions = async ({
  page,
  token,
  userType: user_type,
  limit: rows_per_page = 10,
  status,
}: GetTransactionsRequest): Promise<AxiosApiResponse<GetTransactionsResponse>> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(makeUrlWithParams('/transaction', { page, user_type, rows_per_page, status }), config);
};

interface GetTransactionByIdRequest {
  id: string;
  token: string;
  userType: 'vendor' | 'customer';
}

const getTransactionById = async ({ id, token, userType }: GetTransactionByIdRequest) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get<ApiResponse<Transaction>>(`/transaction/${id}?&user_type=${userType}`, config);
};

interface LeaveReviewRequest {
  id: string;
  token: string;
  data: {
    review: string;
    rating: number;
  };
}

interface LeaveReviewResponse {
  transaction: Transaction;
  balance: Balance;
  review: unknown;
}

const leaveReview = async ({ id, token, data }: LeaveReviewRequest) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.put<ApiResponse<LeaveReviewResponse>>(`/transaction/${id}/review`, data, config);
};

export { getTransactions, getTransactionById, leaveReview };
