import { axios } from 'src/utils';
import { ApiResponse } from 'src/types';

// ===== Customers =====
const verifySmsCustomer = async (data) => {
  return await axios.post(`/auth/register/customer/verify-phone`, data);
};

const sendVerifySmsCustomer = async (data) => {
  return await axios.post(`/auth/register/customer/send-phone-code`, data);
};

const verifyEmailCustomer = async (data) => {
  return await axios.post(`/auth/register/customer/verify-email`, data);
};

interface SendVerifyPinResponseData {
  user: any;
  token: string;
}
const sendVerifyPin = async (data) => {
  return await axios.post<ApiResponse<SendVerifyPinResponseData>>('/auth/register/verify', data);
};

interface ResendVerifyPinBody {
  user_id: string | number;
  user_type: 'customer' | 'vendor';
  type_flag: 'email' | 'phone';
}

const resendVerifyPin = async (body: ResendVerifyPinBody) => {
  return await axios.post('/auth/pin/resend', body);
};

interface SendRecoveryPinBody {
  pin: string;
  user_id: string;
}

interface SendRecoveryPinResponseData {
  token: string;
}
const sendRecoveryPin = async (body: SendRecoveryPinBody) => {
  return await axios.post<ApiResponse<SendRecoveryPinResponseData>>('/auth/recovery/verify', body);
};

// ===== Vendor =====
const verifySmsVendor = async (data) => {
  return await axios.post(`/auth/register/vendor/verify-phone`, data);

  // {
  //     "ok": true,
  //     "data": {
  //     "message": "success send. verify phone"
  // }
  // }
};

const sendVerifySmsVendor = async (data) => {
  return await axios.post(`/auth/register/vendor/send-phone-code`, data);
};

export {
  verifySmsCustomer,
  sendVerifySmsCustomer,
  verifySmsVendor,
  sendVerifySmsVendor,
  verifyEmailCustomer,
  sendVerifyPin,
  resendVerifyPin,
  sendRecoveryPin,
};
