import React, { Suspense, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteFallback } from 'src/components';
import { WEBVIEW_EVENTS } from 'src/constants';

const AuthPage = React.lazy(() => import('./pages/AuthPage/AuthPage'));
const CustomerPage = React.lazy(() => import('./pages/CustomerPage'));
const VendorAuth = React.lazy(() => import('./pages/VendorAuth/VendorAuth'));
const VendorPage = React.lazy(() => import('./pages/VendorPage'));

const useRoutes = (isAuth: boolean) => {
  const history = useHistory();

  const customerToken = useSelector<any>(({ customerReducer: { customerToken } }) => customerToken);
  const vendorToken = useSelector<any>(({ vendorReducer: { vendorToken } }) => vendorToken);

  useEffect(() => {
    const handleMessageFromRN = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.event === WEBVIEW_EVENTS.AppCheck) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: WEBVIEW_EVENTS.AppAlive }));
      }
    };

    document.addEventListener('message', handleMessageFromRN);

    return () => {
      document.removeEventListener('message', handleMessageFromRN);
    };
  }, [history]);

  if (isAuth && customerToken) {
    return (
      <Switch>
        <Route path="/customer">
          <Suspense fallback={<RouteFallback />}>
            <CustomerPage />
          </Suspense>
        </Route>
      </Switch>
    );
  }

  if (isAuth && vendorToken) {
    return (
      <Switch>
        <Route path="/vendor">
          <Suspense fallback={<RouteFallback />}>
            <VendorPage />
          </Suspense>
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/vendor">
        <Suspense fallback={<RouteFallback />}>
          <VendorAuth />
        </Suspense>
      </Route>
      <Route path="/customer">
        <Suspense fallback={<RouteFallback />}>
          <AuthPage />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default useRoutes;
