import { axios } from 'src/utils';
import { ApiResponse, Vendor } from 'src/types';

const vendorRegisterDevice = async (data) => {
  return await axios.post(`/pos-device/${data.deviceId}/register`, { user_id: data.userId });
};

const vendorSignup = async (data) => {
  return await axios.post(`/auth/register/vendor`, data);
};

const getVendorById = async (id: string | number, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get<ApiResponse<Vendor>>(`/vendor/${id}`, config);
  return response.data;
};

const getVendor = async (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get('/auth/user', config);

  return response.data;
};

export { vendorRegisterDevice, vendorSignup, getVendorById, getVendor };
