import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import goBackArrow from 'src/assets/arrows/blue-left-arrow.png';

import './LayoutHeader.css';

export interface LayoutHeaderProps {
  title: string;
  subtitle?: string;
  hideBackArrow?: boolean;
  right?: ReactNode;
}

export const LayoutHeader = ({ title, subtitle, hideBackArrow, right }: LayoutHeaderProps) => {
  const history = useHistory();
  return (
    <div className="layout-header">
      {!hideBackArrow && (
        <img src={goBackArrow} onClick={history.goBack} alt="go back" className="layout-header__back-arrow" />
      )}
      <div className="layout-header__title">
        <span>{title}</span>
        {subtitle && <span className="layout-header__subtitle">{subtitle}</span>}
      </div>

      {right && <div className="layout-header__right">{right}</div>}
      <div />
    </div>
  );
};
