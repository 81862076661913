import { axios } from 'src/utils';

interface SendResetPasswordPinBody {
  email: string;
}

const sendResetPasswordPin = async (body: SendResetPasswordPinBody) => {
  return await axios.post('/auth/recovery/send-email', body);
};

interface SetNewPasswordBody {
  password: string;
  token: string;
}

const setNewPassword = async (body: SetNewPasswordBody) => {
  return await axios.post('/auth/recovery/set-password', body);
};

const passwordIsVerify = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.post(`/password`, data, config);
};

const changePassword = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.put(`/password`, data, config);
};

export { sendResetPasswordPin, setNewPassword, passwordIsVerify, changePassword };
